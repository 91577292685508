import { createUseStyles } from 'react-jss';

export default createUseStyles({
  imageWrap: {
    width: (width) => width,
    '@media (max-width: 799px)': {
      width: '100% !important',
    },
  },
  imageLink: {
    display: 'block',
    width: (width) => width,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
    '@media (max-width: 799px)': {
      width: '100% !important',
    },
  },
  videWrap: {
    width: '100%',
    paddingTop: 'calc(100% * 9 / 16)',
    position: 'relative',
  },
  player: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});
