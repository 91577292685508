import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors, spacing, fonts, components }) => ({
  container: {
    margin: spacing * 2,
    paddingBottom: components.curtain.height * 1.5,
  },
  routeLink: {
    display: 'inline',
    ...fonts.subtitleSmall,
    color: colors.text.desktop,
    '&>button': {
      display: 'inline',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      cursor: 'pointer',
      '@media (hover)': {
        '&:hover': {
          color: colors.button.active,
        },
      },
    },
  },
  title: {
    ...fonts.titleBold,
    marginTop: spacing,
    '@media (max-width: 799px)': {
      ...fonts.titleBold['@media (max-width: 799px)'],
      marginTop: 2.5 * spacing,
    },
  },
  section: {
    borderTop: [1, 'solid', colors.gray],
    marginTop: 2 * spacing,
    padding: [spacing * 2, 0],
    '&:last-of-type': {
      borderBottom: [1, 'solid', colors.gray],
    },
    '@media (max-width: 799px)': {
      marginTop: spacing,
      padding: [spacing, 0],
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  },
  quoteSection: {
    padding: [spacing * 2, 0],
    '&:last-of-type': {
      borderBottom: [1, 'solid', colors.gray],
    },
    '@media (max-width: 799px)': {
      marginTop: spacing,
      padding: [spacing, 0],
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  },
  dateSection: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    paddingRight: '10%',
  },
  text16: {
    ...fonts.subtitle,
    color: colors.text.desktop,
    '@media (max-width: 799px)': {
      ...fonts.subtitleSmall,
      color: colors.black,
    },
  },
  quote: {
    ...fonts.title,
    color: colors.text.desktop,
  },
  text26: {
    ...fonts.title,
    color: colors.text.desktop,
    marginBottom: '1em',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  linksWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
