import React from 'react';
import PropTypes from 'prop-types';

import Arrow from '@/icons/Arrow';

import useStyles from './styles';

const Link = ({ url, children }) => {
  const classes = useStyles();

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
      <div className={classes.svgWrap}>
        <Arrow />
      </div>
      {children}
    </a>
  );
};

Link.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Link;
