import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage } from 'gatsby-plugin-image';
import Player from 'react-player';

import useStyles from './styles';

const Media = ({ kind, image, size, url, link }) => {
  const classes = useStyles(size);

  return (
    <>
      {kind === 'image' && (
        <div className={classes.imageWrap}>
          <GatsbyImage image={image} alt={url} />
        </div>
      )}
      {kind === 'image-link' && (
        <a href={link} target="_blank" rel="noopener noreferrer" className={classes.imageLink}>
          <GatsbyImage image={image} alt={url} />
        </a>
      )}
      {kind === 'video' && (
        <div className={classes.videWrap}>
          <Player url={url} width="100%" height="100%" className={classes.player} controls />
        </div>
      )}
    </>
  );
};

Media.defaultProps = {
  size: '100%',
  link: '#',
  image: {},
};

Media.propTypes = {
  kind: PropTypes.oneOf(['image', 'image-link', 'video']).isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({}),
};

export default Media;
