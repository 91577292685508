/* eslint-disable react/no-array-index-key */
import React, { useContext, useCallback, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { LayoutContext } from '@/components/Layout';

import Media from './Media';
import Link from './Link';
import useStyles from './styles';

const Item = ({ pageContext }) => {
  const { menu, routeBack, date, author, title, text, media, links, quote } = pageContext;
  const { handleRedirect } = useContext(LayoutContext);
  const classes = useStyles();
  const parsedDate = useMemo(() => date.replace(/T.+$/, '').split('-').reverse().join('.'), [date]);

  const handleClick = useCallback(() => handleRedirect(routeBack), [handleRedirect, routeBack]);

  return (
    <div className={classes.container}>
      <p className={classes.routeLink}>
        <button type="button" onClick={handleClick}>
          {`${menu} > `}
        </button>{' '}
        <span>{title}</span>
      </p>
      <h1 className={classes.title}>{title}</h1>
      <section className={cn(classes.section, classes.dateSection)}>
        <p className={classes.text16}>{parsedDate}</p>
        <p className={classes.text16}>{author}</p>
      </section>
      {media && media.map(({ url, ...rest }) => <Media key={url} url={url} {...rest} />)}
      {text && (
        <section className={classes.section}>
          {text.map((t, key) => (
            <p key={key} className={classes.text26}>
              {t}
            </p>
          ))}
        </section>
      )}
      {quote && (
        <section className={classes.quoteSection}>
          <p className={classes.quote}>{quote.text}</p>
          <p className={classes.quote}>{quote.author}</p>
        </section>
      )}
      {links && (
        <section className={cn(classes.section, classes.linksWrap)}>
          {links.map(({ name, url }) => (
            <Link key={url} url={url}>
              {name}
            </Link>
          ))}
        </section>
      )}
    </div>
  );
};

Item.propTypes = {
  pageContext: PropTypes.shape({
    menu: PropTypes.string.isRequired,
    routeBack: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    quote: PropTypes.shape({
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    }),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        kind: PropTypes.oneOf(['image', 'image-link', 'video']).isRequired,
        url: PropTypes.string.isRequired,
        size: PropTypes.string,
        link: PropTypes.string,
        image: PropTypes.shape({}),
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
};

export default Item;
export { default as Head } from '@/components/Head';
