import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors, spacing, fonts, components }) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    marginRight: spacing * 7,
    outline: 'none',
    ...fonts.title,
    color: colors.link.primary,
    textDecoration: 'none',
    '@media (hover)': {
      '&:hover': {
        color: colors.link.hover,
        '& *': {
          color: colors.link.hover,
        },
      },
    },
    '&:active': {
      color: colors.link.click,
      '& *': {
        color: colors.link.click,
      },
    },
    '@media (max-width: 799px)': {
      justifyContent: 'center',
      height: components.dropDown.height.mobile,
      width: '100%',
      marginRight: 0,
      marginTop: spacing,
      boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      ...fonts.subtitleBold,
      color: colors.black,
    },
  },
  svgWrap: {
    marginRight: spacing,
    lineHeight: 0,
    '@media (max-width: 799px)': {
      display: 'none',
    },
  },
}));
